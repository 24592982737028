import { useRuntimeConfig } from 'src/services/RuntimeConfig'
import { boot } from 'quasar/wrappers'
import Plausible from 'plausible-tracker'
import { createEntity, getEntityType } from 'src/helper/EntityHelper'
import { AuthorEntity } from 'src/entities/AuthorEntity'
import { useAuthUserStore } from 'stores/auth-user-store'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $plausible: ReturnType<typeof Plausible> | undefined
  }
}

const runtimeConfig = useRuntimeConfig()

const plausibleOptions = {
  domain: runtimeConfig.APP_BASE_URL,
  apiHost: runtimeConfig.PLAUSIBLE_API_BASE_URL,
  trackLocalhost: false,
  hashMode: false
}

const plausible = runtimeConfig.PLAUSIBLE_ENABLED ? Plausible(plausibleOptions) : undefined

plausible?.enableAutoPageviews()
plausible?.enableAutoOutboundTracking()

export default boot(({ app }) => {
  app.config.globalProperties.$plausible = plausible
})

// Every new tracking action must be configured in our Plausible instance!!!
export enum TrackingAction {
    EMAIL_SIGNUP = 'emailSignUp',
    SEARCH_ENTITY = 'searchEntity',
    OPEN_ASSET = 'openAsset',
    OPEN_VIEW = 'openView',
    OPEN_FILTER_VIEW = 'openFilterView',
    OPEN_AUTHOR = 'openAuthor',
    OPEN_KEY_EVENT = 'openKeyEvent',
    OPEN_TYPE = 'openType',
    SELECT_TIME_RANGE = 'selectTimeRange',
    FILTER_SOURCE = 'filterSource',
    CLICK_ACTION_BUTTON = 'clickActionButton',
    SELECT_CHART_TIME_RANGE = 'selectChartTimeRange',
    OPEN_INFO_DIALOG = 'openInfoDialog',
    OPEN_MESSAGE_IN_SOURCE = 'openMessageInSource',
    OPEN_VIDEO_DIALOG = 'openVideoDialog',
    TRANSLATE_MESSAGE = 'translateMessage',
    FILTER_MESSAGE_TYPE = 'filterMessageType',
    SHOW_NEW_EVENTS = 'showNewEvents',
    OPEN_WATCHLIST_SETTINGS = 'openWatchlistSettings',
    DISPLAY_WATCHLIST_ASSETS_IN_VIEW = 'displayWatchlistAssetsInView',
    OPEN_EXPLORE_PAGE = 'openExplorePage',
    EXPORT_CSV = 'exportCsv',
    SELECT_PARTITION = 'selectPartition',
    OPEN_PILL_ACTION = 'openPillAction',
    ADD_FILTER = 'addFilter',
    FILTER_AUTHOR_SCORE = 'filterAuthorScore',
    FILTER_MESSAGE_TITLE_CLASS = 'filterMessageTitleClass',
    LOGIN_USER = 'loginUser',
    LOGOUT_USER = 'logoutUser',
    RESET_PASSWORD = 'resetPassword',
    FOLLOW_ENTITY = 'followEntity',
    UNFOLLOW_ENTITY = 'unfollowEntity',
    OPEN_USERDATA_SETTINGS = 'openUserdataSettings',
    OPEN_SUBSCRIPTION_SETTINGS = 'openSubscriptionSettings',
    SHOW_MARKET_OVERVIEW = 'showMarketOverview',
    SHOW_MORE_SUMMARY_SOURCES = 'showMoreSummarySources',
    SHOW_MORE_MESSAGES = 'showMoreMessages',
    SHOW_MORE_EVENTS = 'showMoreEvents',
    SHOW_MORE_RATINGS = 'showMoreRatings',
    ENABLE_TUTORIAL_CONTENT = 'enableTutorialContent',
    SHOW_EXECUTIVES = 'showExecutives',
    SHOW_SUBSIDIARIES = 'showSubsidiaries',
    OPEN_REGISTER = 'openRegisterPage',
    START_TRIAL = 'startTrial',
    CLICK_BUY_NOW = 'clickBuyNow',
    CLICK_UPGRADE = 'clickUpgrade',
    SHOW_SUBSCRIPTION_DETAILS = 'showSubscriptionDetails',
    CREATE_ACCOUNT = 'createAccount',
    ENTER_INVOICE_DATA = 'enterInvoiceData',
    CLICK_PAY_NOW = 'clickPayNow'
}
export type TrackingContext = { [propName: string]: string | number | boolean }

function trackClick (
  action: TrackingAction,
  componentIdentifier: string,
  context?: TrackingContext | undefined,
  callback?: (() => void)
): void {
  plausible?.trackEvent(
    action,
    {
      props: {
        componentIdentifier,
        ...context,
        user: useAuthUserStore().user?.username || 'unknown'
      }
    }
  )
  if (callback !== undefined) {
    callback()
  }
}

function trackAsset (
  assetId: number,
  componentIdentifier: string,
  callback?: (() => void)
): void {
  const entity = createEntity(assetId, getEntityType(assetId))
  if (entity === undefined) {
    return
  }
  trackClick(entity.getTrackingAction(), componentIdentifier, entity.getTrackingContext(), callback)
}

function trackAuthor (
  authorName: string,
  source: string,
  componentIdentifier: string,
  callback?: (()=> void)
): void {
  const author = new AuthorEntity(authorName, source)
  trackClick(author.getTrackingAction(), componentIdentifier, author.getTrackingContext(), callback)
}

export { plausible, trackClick, trackAsset, trackAuthor }
